<template>
    <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link class="crumbs_item" tag="a" to="/admin/PromotionManage/packageManage">套餐管理 / </router-link>
          <span class="crumbs_item crumbs_last">套餐{{$route.query.operation == 1 ? '新建':'编辑' }}</span>
        </span>
      </div>
    </div>

    <div class="all_content_box">
      <div class="L_R_inner">
        <span class="all_left_name all_required">套餐名称：</span>
        <div class="right_Div">
          <a-input class="all_input" v-model="form.packageName" placeholder="请输入套餐名称" />
        </div>
      </div>
      <a-row>
        <a-col :span="9">
          <div class="L_R_inner">
            <span class="all_left_name all_required">移动端，pc商品列表图：
              <div class="all_left_name minredtext">建议尺寸：360*313px</div>
              <div class="all_left_name greytext">（最多传1张）</div>
            </span>

            <div class="right_Div">

              <a-upload
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
                list-type="picture-card"
                :showUploadList="false"
                :loading="loadingMobilePc"
                :customRequest="customRequestMobilePc"
              >
                <img v-if="form.pcMobileListPicture" :src="form.pcMobileListPicture" style="max-width:180px" alt="avatar" />
                <div v-else>
                  <a-icon :type="loadingMobilePc ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">
                    上传图片
                  </div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-col>
        <!-- <a-col :span="15">
          <div class="L_R_inner">
            <span class="all_left_name all_required">移动端详情图：
              <div class="all_left_name minredtext">建议尺寸：750*530px</div>
              <div class="all_left_name greytext">（最多传5张）</div>
            </span>
            <div class="right_Div" style="flex:1">
              <a-upload 
                list-type="picture-card" 
                class="uploader-image" 
                :file-list="mobileDetailPictureList" 
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
                :loading="loadingMobile"
                :beforeUpload="MobileBeforeUpload" 
                :customRequest="customRequestMobile"
                @change="mobileDetailChange"
                :data="{
                  dirName: 'image'
                }">
                <div v-if="mobileDetailPictureList.length < 5">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-col> -->
      </a-row>
      <a-row>
        <a-col :span="30">
          <div class="L_R_inner">
            <span class="all_left_name all_required">pc详情图：
              <div class="all_left_name minredtext">建议尺寸：750*750px</div>
              <div class="all_left_name greytext">（最多传1张）</div>
            </span>

            <div class="right_Div" style="flex:1">
              <a-upload 
                list-type="picture-card" 
                class="uploader-image" 
                :file-list="pcDetailPictureList" 
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
                :loading="loadingPC"
                :beforeUpload="PcBeforeUpload" 
                :customRequest="customRequestPc"
                @change="pcDetailChange"
                :data="{
                  dirName: 'image'
                }">
                <div v-if="pcDetailPictureList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-col>
      </a-row>

       <div class="L_R_inner">
        <span class="all_left_name">套餐简介：</span>
        <div class="right_Div">
          <a-textarea placeholder="请输入套餐简介"  v-model="form.introduction" :rows="4" style="resize:none;" />
        </div>
      </div>

      <div class="L_R_inner" style="align-items: center;" v-show="false">
        <span class="all_left_name all_required">活动时间：</span>
        <div class="right-box">
          <a-radio-group v-model="form.timeType">
            <div class="right_Div">
              <a-radio :value="1">不限</a-radio>
              <a-radio :value="2">设置时间</a-radio>
              <template v-if="form.timeType == 2">
                <div class="right_Div">
                  <a-date-picker v-model="form.startTime" format="YYYY-MM-DD HH:mm:ss" placeholder="选择开始时间" :disabled-date="disabledDate"
                    :disabled-time="disabledDateTime" :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" />
                </div>
                <div class="right_Div">
                  至
                </div>
                <div class="right_Div">
                  <a-date-picker v-model="form.endTime" format="YYYY-MM-DD HH:mm:ss" placeholder="选择结束时间" :disabled-date="disabledDate"
                    :disabled-time="disabledDateTime" :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" />
                </div>
              </template>
            </div>
          </a-radio-group>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">相关商品：</span>
        <div class="right-box">
          <div class="box-first">
            <div class="right_Div">
              <a-button type="primary" class="btn" @click="addProduct()">添加</a-button>
              <span class="tips">请至少添加2种商品</span>
            </div>
          </div>
        </div>
      </div>
      <a-table class="table-template" 
      style="margin-left:125px;width:910px"
        :columns="columns" 
        :rowKey="item=>item.productId"
        :data-source="form.productList" 
        @change="onPage"
        :pagination="{
          total: total,
          showTotal: (res) => `共${total}条`,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
        }">
        <template slot="index" slot-scope="item, row, index">
            {{ (tableQuery.pageNumber - 1) * tableQuery.pageSize + index + 1 }}
        </template>

        <!-- 数量 -->
        <template slot="count" slot-scope="item, row, index">
          <a-input-number id="inputNumber" :disabled="item.type==1 || item.type==4" v-model="form.productList[(tableQuery.pageNumber - 1) * tableQuery.pageSize + index].count" :min="1" :max="10000" @change="onSumPrice" />
        </template>

        <!-- 原价 -->
        <template slot="price" slot-scope="item">
          ￥{{ item ? (Number(item)).toFixed(2) : '0.00' }}
        </template>

        <template slot="operation" slot-scope="item, row, index">
          <a style="color:#3681F0" @click="onSelectIndexDel(1,index)">清除</a>
        </template>
      </a-table>
      <div class="L_R_inner">
        <span class="all_left_name">总价格：</span>
        <div class="right_Div redtext">
          ￥{{(form.originalPrice).toFixed(2)}}元
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">套餐价：</span>
        <div class="right_Div">
          <a-input class="all_input else_input" v-model="form.packagePrice" @blur="inputContent" placeholder="" />
        </div>
      </div>
      <div class="L_R_inner">
        <div class="Up_Down_inner">
          <span class="all_left_name all_required">app套餐详情：</span>
          <UE editorId="introduceEditor2" :initContent="form.mobileDetail" @input="getContent1" />
        </div>
      </div>
      <div class="L_R_inner">
        <div class="Up_Down_inner">
          <span class="all_left_name all_required">pc套餐详情：</span>
          <UE editorId="introduceEditor3" :initContent="form.pcDetail" @input="getContent2" />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">先后购买：</span>
        <div class="right-box">
          <a-radio-group v-model="form.buyConfig">
            <div class="box-first">
              <div class="right_Div">
                <a-radio :value="1">必须一起买才可享受套餐价</a-radio>
                <a-radio :disabled="tipShow" :value="2">一起买、先后买均可享受套餐价</a-radio>
              </div>
            </div>
            <div class="Up_Down_inner"  v-show="tipShow">
              <span class="redtext" style="margin-top:0">
                当设置的套餐价格过低时，将无法设置先后购买功能，请先调整后再设置
              </span>
            </div>
          </a-radio-group>
        </div>
      </div>
      <div class="L_R_inner" >
        <span class="all_left_name all_required">套餐上架设置：</span>
        <div class="right-box">
          <a-radio-group v-model="form.groundSet">
            <div class="box-first" style="width:570px">
              <div class="right_Div" style="display:flex">
                <a-radio :value="1">暂不上架</a-radio>
                <a-radio :value="2">提交及上架</a-radio>
                <a-radio :value="3">
                  <span>设置上架时间</span>
                  <a-date-picker show-time  :disabled-date="disabledDate" :disabled="form.groundSet!='3'" valueFormat="YYYY-MM-DD HH:mm:ss" v-model="form.groundTime" format="YYYY-MM-DD HH:mm:ss" style="margin-left:10px;"/>
                </a-radio>
              </div>
            </div>
          </a-radio-group>
        </div>
      </div>

    </div>

    <!-- <div class="title-blod">机构购买设置</div>

    <div class="all_content_box">
      <div class="L_R_inner">
        <div class="right_Div">
          <a-radio-group name="radioGroup" :default-value="1">
            <a-radio :value="1">
              不允许机构购买
            </a-radio>
            <a-radio :value="2">
              允许机构购买
            </a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required align">机构价：</span>
        <div class="right_Div">
          <a-input class="all_input else_input" placeholder="" />元
        </div>
      </div>
      <div class="L_R_inner">
        <div class="right_Div">
          <a-radio>需达到</a-radio>
          <a-input class="all_input else_input" placeholder="" />个购买方可使用机构价
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name">以兑换券形式购买：</span>

        <div class="right_Div">
          <a-radio-group v-model="valuebuy" @change="onChange">
            <a-radio :value="1">否</a-radio>
            <a-radio :value="2">是</a-radio>
          </a-radio-group>
          <div class="right_Div">
            <a-select placeholder="选择兑换券" @change="handleChange">
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option value="lucy">
                行大运
              </a-select-option>
              <a-select-option value="disabled" disabled>
                暴富
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>
    </div> -->

    <div class="button">
      <a-button type="primary" @click="onSavaForm()" :loading="loading" class="btn">确认</a-button>
      <a-button class="all_boder_btn btn" @click="$router.go(-1)">取消</a-button>
    </div>

    <a-modal v-model="relation" width='1000px' title="商品列表"  :centered='true' :closable='false'>
      <div class="search-box">
        <a-select class="search-input" v-model="productForm.type" placeholder="请选择商品类型" allowClear >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="1">课程</a-select-option>
          <a-select-option :value="2">图书</a-select-option>
          <a-select-option :value="3">教具</a-select-option>
          <!-- <a-select-option :value="4">模拟考试</a-select-option>
          <a-select-option :value="5">电子照片</a-select-option>
          <a-select-option :value="6">延期</a-select-option>
          <a-select-option :value="7">补考</a-select-option>
          <a-select-option :value="8">直播</a-select-option>
          <a-select-option :value="9">其他</a-select-option> -->
        </a-select>
        <a-input class="search-input" v-model="productForm.name" placeholder="请输入商品名称" />
        <a-button class="search-btn" type="primary" @click="onProductSearch">搜索</a-button>
      </div>

      <div class="table-box">
        <a-table class="table-template1" 
          :row-selection="{
            columnTitle:'选择',
            selectedRowKeys: selectedProductRowKeys,
            onChange: onSelectProduct,
            columnWidth:'70px'
          }" 
          bordered
          :rowKey="item=>item.productId"
          :columns="productcolumns" 
          :data-source="productData"
          :loading="productLoad"
          @change="onProductPage"
          :pagination="{
            total:productTotal,
            current:productForm.page,  
            defaultpageSize:productForm.size, 
            showSizeChanger: true,
            showTotal: function(productTotal, range){
              return `共${productTotal}条`
            }
          }">

          <template slot="index" slot-scope="item, row, index">
            {{ (productForm.page - 1) * productForm.size + index + 1 }}
          </template>
          <!-- 商品类型 -->
          <template slot="productType" slot-scope="item">
            <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
            <span v-if="item==1">课程</span>
            <span v-else-if="item==2">图书</span>
            <span v-else-if="item==3">教具</span>
            <span v-else-if="item==4">模拟考试</span>
            <span v-else-if="item==5">电子照片</span>
            <span v-else-if="item==6">延期</span>
            <span v-else-if="item==7">补考</span>
            <span v-else-if="item==8">直播</span>
            <span v-else-if="item==9">其他</span>
            <span v-else>-</span>
          </template>

        </a-table>

        <a-table class="table-template2" 
          style="width:30%"
          bordered
          :rowKey="item=>item.productId"
          :columns="selectcolumns" 
          :data-source="selectData" 
          :pagination="{
            total: selectTotal,
            showTotal: (res) => `共${selectTotal}条`,
          }">

          <template slot="num">
            当前已选中 <span class="blueText">{{selectTotal}}</span> 个商品
          </template>

          <template slot="operation" slot-scope="item, row, index">
            <a @click="onSelectIndexDel(2,index)" class="blueText">清除</a>
          </template>

        </a-table>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="relationOk">确认</a-button>
        <a-button @click="relation = false">取消</a-button>
      </template>

    </a-modal>
  </div>
</template>

<script>
  import moment from "moment";
  import UE from "@/components/UE.vue";
  const productcolumns = [{
      title: "序号",
      align: "center",
      width:'70px',
      dataIndex: "index",
      scopedSlots: {
        customRender: "index",
      },
    },
    {
      title: "商品类型",
      align: "center",
      width:'140px',
      dataIndex: "productType",
      scopedSlots: {
        customRender: "productType",
      },
    },
    {
      title: "商品名称",
      ellipsis:true,
      align: "center",
      dataIndex: "productName",
    },
  ];
  const selectcolumns = [{
      align: "center",
      dataIndex: "productName",
      width: 120,
      ellipsis:true,
      colSpan: 2,
      scopedSlots: {
        customRender: "productName",
        title: "num",
      },
    },
    {
      title: "操作",
      align: "center",
      colSpan: 0,
      width: 40,
      dataIndex: "operation",
      scopedSlots: {
        customRender: "operation",
      },
    },
  ];
  const columns = [{
      title: "序号",
      align: "center",
      width: "80px",
      dataIndex: "index",
      scopedSlots: {
        customRender: "index",
      },
    },
    {
      title: "商品名称",
      ellipsis:true,
      align: "center",
      width: "500px",
      dataIndex: "productName",
    },
    {
      title: "数量",
      align: "center",
      // dataIndex: "count",
      width: "120px",
      scopedSlots: {
        customRender: "count",
      },
    },
    {
      title: "原价",
      align: "center",
      dataIndex: "price",
      scopedSlots: {
        customRender: "price",
      },
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "operation",
      scopedSlots: {
        customRender: "operation",
      },
    },
  ];
  export default {
    // 可用组件的哈希表
    components: {
      UE,
    }, // 接收传值
    props: {}, // 数据对象
    data() {
      return {
        value: 1,
        valueSet: 1,
        valuebuy: 1,
        
        // 图片模版
        imageTemplate: {
          uid: 1,
          status: "done",
          thumbUrl: "",
          url: "",
          response: {
            data: {
              src: "",
            },
          },
        },
        loading:false,
        columns,
        total: 0,
        pageNumber: 1, //页码
        pageSize: 10, // 页数
        relation: false,
        selectcolumns,
        ptotal: 0,
        ppageNumber: 1, //页码
        ppageSize: 10, // 页数

        productArry:[],  // 已选商品--暂存
        selectData:[],  // 已选商品
        selectTotal: 0,

        selectedProductRowKeys:[],
        productData:[],  // 商品列表
        productcolumns,  // 商品表头
        productTotal:0, // 商品数量
        productLoad:false,
        // 商品参数
        productForm: {
          noGround: 1,
          name:'',
          type:undefined,
          page:1,
          size:10,
          noFree: 0,
          noExam: 1
        },

        form:{
          packageId:'',
          packageName: '',   //套餐名称
          pcMobileListPicture: '',  //移动端，PC商品列表图
          mobileDetailPicture: [],   //	移动端详情图
          pcDetailPicture: [],  // PC端详情图
          introduction: '',  // 套餐简介
          timeType:1,   // 活动时间类型1.不限 2.时间段
          startTime:'',   // 	活动开始时间
          endTime:'',  //活动结束时间 
          productList:[],  
          originalPrice: 0, // 原总价
          packagePrice:'',    // 套餐价
          mobileDetail:'',
          buyConfig:1,   // 先后购买设置1.必须一起买才可享受套餐价 2.一起买、先后买均可享受套餐价
          groundSet: 1,
          groundTime:''
        },
        tableQuery:{
          pageNumber:0,
          pageSize:0
        },
        loadingMobilePc:false,
        loadingMobile:false,
        loadingPC:false,

        mobileDetailPictureList: [],
        pcDetailPictureList: [],

        mobileIndex:0,
        pcIndex:0,

        tipShow:false
      };
    }, // 事件处理器
    methods: {
      moment,
      handleChange(value) {
        // console.log(`selected ${value}`);
      },

      range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
      },

      // 禁止选择今天之前的日期
      disabledDate(current) {
        return current && current < moment().subtract(1, 'days').endOf('day')
      },

      disabledDateTime() {
        return {
          disabledHours: () => this.range(0, 24).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      },
      // 分页
      onPage(e) {
        this.tableQuery.pageNumber = e.current;
        this.tableQuery.pageSize = e.pageSize;
      },
      // 添加商品
      addProduct(){
        this.relation = true
        this.selectedProductRowKeys = []
        this.selectData = []
        this.form.productList.forEach(element => {
          this.selectedProductRowKeys.push(element.productId)
          this.selectData.push({
            couponPrice: element.price,
            productId:element.productId,
            productName:element.productName,
            productType:element.type,
            count:element.count
          })
        });
        this.selectTotal = this.selectData.length
        this.total=this.form.productList.length
      },
      // 商品分页
      onProductPage(e) {
        this.productForm.page = e.current;
        this.productForm.size = e.pageSize;
        this.getProductList()
      },
      
      // 计算总价格
      onSumPrice(){
        let count = 0
        this.form.productList.forEach(element => {
          if(element.price){
            count = element.price * element.count + count
          }
        });
        this.form.originalPrice = count
      },

      // 非空字段校验
      FunCheck(){
        let isCheck = false
        if(!this.form.packageName){ // 校验套餐名称
          isCheck = true
          this.$message.warning("请输入套餐名称");
          return isCheck
        }
        if(!this.form.pcMobileListPicture){ // 校验移动端，pc商品列表图
          isCheck = true
          this.$message.warning("请上传移动端，pc商品列表图");
          return isCheck
        }
        // if(!this.mobileDetailPictureList.length){ // 校验移动端详情图
        //   isCheck = true
        //   this.$message.warning("请上传移动端详情图");
        //   return isCheck
        // }
        if(!this.pcDetailPictureList.length){ // 校验pc详情图
          isCheck = true
          this.$message.warning("请上传pc详情图");
          return isCheck
        }
        if(this.form.timeType == 2 && (!this.form.startTime || !this.form.endTime)){ // 校验活动时间
          isCheck = true
          this.$message.warning("请设置活动时间");
          return isCheck
        }
        if(!this.form.productList.length){ // 校验相关商品
          isCheck = true
          this.$message.warning("请添加相关商品");
          return isCheck
        }
        if(this.form.productList.length < 2){ // 校验相关商品
          isCheck = true
          this.$message.warning("请至少添加2种商品");
          return isCheck
        }
        if(!this.form.packagePrice){ // 校验入套餐价
          isCheck = true
          this.$message.warning("请输入套餐价");
          return isCheck
        }
        if(!this.form.packagePrice){ // 校验入套餐价
          isCheck = true
          this.$message.warning("请输入套餐价");
          return isCheck
        }
        if(this.form.packagePrice > this.form.originalPrice){ // 校验入套餐价
          isCheck = true
          this.$message.warning("套餐价格需小于或等于总价格");
          return isCheck
        }
        if(!this.form.mobileDetail){ // 校验app套餐详情
          isCheck = true
          this.$message.warning("请输入app套餐详情");
          return isCheck
        }
        if(!this.form.groundSet){ // 校验上架时间
          isCheck = true
          this.$message.warning("请配置上架设置");
          return isCheck
        }
        if(this.form.groundSet == 3 && !this.form.groundTime){ // 上架时间
          isCheck = true
          this.$message.warning("请选择上架时间");
          return isCheck
        }
      },
       
      // 提交数据
      onSavaForm(){
        this.form.mobileDetailPicture = [] 
        this.form.pcDetailPicture = [] 
        if(this.FunCheck()) return  // 非空字段校验
        this.mobileDetailPictureList.forEach(item => {
          this.form.mobileDetailPicture.push(item.url)
        });
        this.pcDetailPictureList.forEach(item => {
          this.form.pcDetailPicture.push(item.url)
        });


        this.loading = true
        this.form.creator = this.form.creator ? this.form.creator : this.$store.state.userInfo.id  // 创建者
        this.form.modifier = this.$store.state.userInfo.id  // 修改者
        
        this.form.mobileDetailPicture = JSON.stringify(this.form.mobileDetailPicture)  // 图片集 转字符
        this.form.pcDetailPicture = JSON.stringify(this.form.pcDetailPicture) // 图片集 转字符
        if(this.form.timeType==2){
          this.form.startTime= this.moment(this.form.startTime).format("YYYY-MM-DD HH:mm:ss")
          this.form.endTime= this.moment(this.form.endTime).format("YYYY-MM-DD HH:mm:ss")
        } else {
          this.form.startTime = ''
          this.form.endTime = ''
        }
        if(this.form.groundSet==3){
          this.form.groundTime= this.moment(this.form.groundTime).format("YYYY-MM-DD HH:mm:ss")
        } else {
          this.form.groundTime = ''
        }
        this.$ajax({
        url: this.form.packageId ? '/hxclass-management/packages/':'/hxclass-management/packages/',
        method: this.form.packageId ? 'put' : 'post',
        params: this.form,  // 如果是包邮用定义包邮的对象
        }).then((res) => {
          this.loading = false
          if (res.code == 200 && res.success) {
            this.$message.success("操作成功");
            this.$router.go(-1);
          } else {
            this.$message.warning(res.message);
          }
        });
      },
      // 富文本内容有变化时触发
      getContent1(e) {
        this.form.mobileDetail = e
      },
      // 富文本内容有变化时触发
      getContent2(e) {
        this.form.pcDetail = e
      },
      
      // 输入套餐价触发
      inputContent(){
        let minArry = []
        this.form.productList.forEach(element => {
          minArry.push(element.price)
        });
        let minPrice = this.form.originalPrice - Math.min(...minArry)  // 套餐最低价

        if(this.form.packagePrice < minPrice){   // 当设置的套餐价不满足要求
          this.tipShow = true
          this.form.buyConfig = 1
        } else {
          this.tipShow = false
        }
      },
      // 窗口确认
      relationOk(e) {
        if(!this.selectData.length){
          this.$message.warning('请选择商品');
          return
        }
        let arry = this.form.productList
        this.form.productList = []
        this.selectData.forEach(element => {
          this.form.productList.push({  // 进行对所选商品赋值
            count:element.count ? element.count : 1,
            price:element.couponPrice,
            productId:element.productId,
            productName:element.productName,
            type:element.productType
          })
        })

        arry.forEach((element) => {
          this.form.productList.forEach((item) => {
            if(element.productId == item.productId){
              item.count = element.count
            }
          });
        });
        this.total=this.form.productList.length
        this.relation = false;
        this.onSumPrice()
      },


      // 文件上传更新进度和单文件上传初始化
      fileProgressCallback(progress, speed, name) {
        /*
        * progress 进度
        * speed 传输速度
        * name 文件名称
        * */
      },
      
      // 移动端，pc商品列表图
      customRequestMobilePc(fileData){
        this.loadingMobilePc = true;

        const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
        // 腾讯云对象存储上传文件
        this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
          // 上传完成
          if (url) {
            this.form.pcMobileListPicture = url
          } else {
            this.$message.error("上传失败");
          }
          this.loadingMobilePc = false;
        });
      },


      // 移动端详情图
      customRequestMobile(e) {
        this.loadingMobile = true;
        const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
        // 腾讯云对象存储上传文件
        this.$cosUpload(e.file,bucket,this.fileProgressCallback,(url, fileName) => {
          // 上传完成
          if (url) {
            this.mobileIndex ++ 
            this.mobileDetailPictureList.push({
              uid: 'code' + this.mobileIndex,
              name: 'image.png',
              status: 'done',
              url: url,
            })
          } else {
            this.$message.error("上传失败");
          }
          this.loadingMobile = false;
        });
      },

      //  移动端上传前钩子
      MobileBeforeUpload(e){
        if (!this.$regular.imgReg.reg.test(e.name)) {
          this.$message.warning(this.$regular.imgReg.msg);
          return false;
        }
      },

      // 删除图片
      mobileDetailChange(e){
        this.mobileDetailPictureList.forEach((element,i) => {
          if(element.uid == e.file.uid){
             this.mobileDetailPictureList.splice(i, 1);
          }
        });
      },

      // pc端详情图
      customRequestPc(e) {
        this.loadingPC = true;
        const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
        // 腾讯云对象存储上传文件
        this.$cosUpload(e.file,bucket,this.fileProgressCallback,(url, fileName) => {
          // 上传完成
          if (url) {
            this.loadingPC = false
            this.pcDetailPictureList.push({
              uid: 'id' + (this.pcDetailPictureList.length),
              name: 'image.png',
              status: 'done',
              url: url,
            })
          } else {
            this.$message.error("上传失败");
          }
          this.loadingPC = false;
        });
      },
      
      //  pc端上传前钩子
      PcBeforeUpload(e){
        if (!this.$regular.imgReg.reg.test(e.name)) {
          this.$message.warning(this.$regular.imgReg.msg);
          return false;
        }
      },

      // 删除图片
      pcDetailChange(e){
        this.pcDetailPictureList.forEach((element,i) => {
          if(element.uid == e.file.uid){
             this.pcDetailPictureList.splice(i, 1);
          }
        });
      },

      // 商品高级检索
      onProductSearch(){
        this.productForm.page = 1
        this.getProductList()
      },
      // 检索商品列表
      getProductList() {
        this.productLoad = true
        this.$ajax({
          method: 'get',
          url: '/hxclass-management/product-archives/manage/select-win',
          params: this.productForm
        }).then((res) => {
          this.productLoad = false
          if (res.code == 200 && res.success) {
            this.productTotal = res.data.total;
            this.productData = res.data.records;
          } else {
            this.$message.error(res.message);
          }
        });
      },
      // 选择商品
      onSelectProduct(selectedRowKeys,v){
        this.selectedProductRowKeys = selectedRowKeys
        this.productArry[this.productForm.page - 1] = v
        // this.selectData = []

        // 暂存数组
        let storageArry = []
        this.productArry.forEach(item => {
          item.forEach(subItem => {
            storageArry.push(subItem)
          });
        });

        // 已选数组和新数组合并
        this.selectData = [...this.selectData, ...storageArry]
        let arry = []
        selectedRowKeys.forEach(element => {
          this.selectData.forEach(item => {
            if(element == item.productId)
              arry.push(item)
            });
        });

        // 并列数组去重
        let newArr = [];
        let obj = {};
        for (var i = 0; i < arry.length; i++) {
          if (!obj[arry[i].productId]) {
            newArr.push(arry[i])
            obj[arry[i].productId] = true
          }
        }

        this.selectData = newArr

        this.selectTotal = this.selectData.length
      },


      // 清除某项已选商品
      onSelectIndexDel(type,index){
        this.selectedProductRowKeys.splice(index,1)
        this.selectData.splice(index,1)
        this.selectTotal = this.selectData.length
        if(type == 1){
          this.form.productList.splice(index,1)
          this.total=this.form.productList.length
        }
        this.onSumPrice()
      },

      // 查询详情
      getFormDetai(){
        this.loading = true
        this.$ajax({
          method: 'get',
          url:"/hxclass-management/packages/" + this.form.packageId,
        }).then(res => {
          this.loading = false
          if (res.code == 200 && res.success) {
            this.form = res.data
            res.data.productList.forEach(element => {
              this.selectedProductRowKeys.push(element.productId)
              this.selectData.push(element)
            });
            this.total=this.form.productList.length
            let mobileArry = JSON.parse(res.data.mobileDetailPicture)
            let pcArry = JSON.parse(res.data.pcDetailPicture)


            mobileArry.forEach((item,i) => {  // 渲染移动端详情图
              this.mobileDetailPictureList.push({
                uid: '-codeId' + (i + 1),
                name: 'image.png',
                status: 'done',
                url: item,
              })
            });

            pcArry.forEach((item,i) => {  // 渲染pc端详情图
              this.pcDetailPictureList.push({
                uid: '-id' + i,
                name: 'image.png',
                status: 'done',
                url: item,
              })
            });
          }
        })
      },
    }, // 生命周期-实例创建完成后调用
    created() {
      this.form.packageId = this.$route.query.id;
      if(this.form.packageId){
        this.getFormDetai()
      }
    }, // 生命周期-实例挂载后调用
    mounted() {
      this.getProductList()
    }, // 生命周期-实例销毁离开后调用
    destroyed() {}, // 计算属性监听
    computed: {}, // 自定义的侦听器
    watch: {
      relation(){
        if(!this.relation){
          this.productForm.name = ''
          this.productForm.type = undefined
          this.productForm.page = 1
          this.productForm.size = 10
          this.getProductList()
        }
      }
    },
  };

</script>

<style lang="less"  scoped>
.all_main_wrap {
  .ant-select {
    width: 124px !important;
  }

  a {
    color: #333333;
  }

  .title-blod {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
  }

  .all_content_box {
    .L_R_inner {
      margin: 24px;
      .minredtext{
        font-size: 12px;
        color: red;
      }

      .redtext {
        font-size: 14px;
        color: red;
      }

      .greytext {
        font-size: 12px;
        color: #888;
      }

      .all_left_name {
        width: 190px;
        text-align: right;
      }

      .align {
        width: 94px;
        text-align: right;
      }

      .vue-ueditor-wrap[data-v-1e1388a0] {
        margin-left: 146px;
        width: 800px;
      }

      .right_Div {
        textarea.ant-input {
          width: 800px;
          height: 100px;
        }

        .all_input {
          width: 337px;
        }

        /deep/.ant-input {
          height: 37px;
        }

        .else_input {
          margin-right: 12px;
          width: 140px;
        }

        .left {
          margin-left: 12px;
        }
      }

      .right-box {
        .box-first {
          width: 471px;

          .right_Div {
            display: inline-block;
            line-height: 37px;

            .ant-select {
              width: 166px;
            }
          }
        }

        .Up_Down_inner {
          margin-left: 20px;

          .all_left_name {
            display: flex;
            align-items: center;
            margin-top: 24px;

            .btn {
              margin: 0px 24px;
            }
          }

          .redtext {
            display: inline-block;
            margin-top: 24px;
            font-size: 14px;
            color: red;
          }
        }
      }
    }
  }

  .button {
    margin-left: 170px;
    .btn {
      margin: 24px 24px 24px 0;
    }
  }

  .tips {
    margin-left: 24px;
    font-size: 14px;
    color: #888;
  }
}

/deep/.ant-modal-body {
  overflow: hidden;
}

/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}

/deep/.ant-modal-header {
  border-bottom: none;
}

/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}

/deep/ .ant-modal-body {
  padding: 14px 36px 0px;
}

.table-template {
  /deep/.ant-table {
    border: none;
    // box-shadow: none;
  }
}

.table-box {
  display: flex;
  justify-content: space-between;
  .table-template1{
    width: 600px;
    margin-right: 20px;
  }
  .table-template2{
    flex: 1;
  }
}

.search-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .search-input {
    width: 36%;
  }
}

.picture {
  width: 60px;
}

</style>

